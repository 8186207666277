<template>
  <OwnersList/>
</template>

<script>
export default {
  name: 'owners',
  components: {
    OwnersList: () => import('@/components/owners/OwnersList')
  }
}
</script>